
@font-face {
    font-family: "Mulish";
    src: local("Mulish"),
     url("../fonts/Mulish-Medium.ttf") format("truetype");
    font-weight: normal;
}
  
@font-face {
    font-family: "Namu";
    src: local("Namu"),
    url("../fonts/NAMU-1750.ttf") format("truetype");
    font-weight: normal;
}
  
  
@media screen and (min-width: 801px) and (max-width: 4000px){
.team-container-wrap{
    height: 100vh;

    display: flex;
    justify-content: right;
    align-items: center;
}
.team-container{
    width: 90%;
}
.team-title p{
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 300;
    font-size: 60px;
    line-height: 72px;
    letter-spacing: 0.04em;

    margin: 0;
    margin-bottom: 60px;

    color: #FFFFFF;
}
.team-title{

    
}
.team-top{
    display: flex;
    flex-direction: row;
    gap: 310px;
}
.team-description p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 180%;

    margin: 0;
    margin-bottom: 60px;

    width: 520px;

    color: #FFFFFF;
}
.team-description{

}
.team-buttons-row{
    display: flex;
    flex-direction: row;
    gap: 40px;
}
.team-button{
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid #69F5FF;
    border-radius: 200px;

    background-color: transparent;

    height: 50px;
    width: 160px;

    cursor: pointer;
    transition: 0.4s;
}
.team-button:hover{
    filter: drop-shadow(0px 0px 4px #5b6e6e) drop-shadow(0px 0px 4px  #5b6e6e) drop-shadow(0px 0px 4px  #5b6e6e) drop-shadow(0px 0px 4px  #5b6e6e);
}
.team-list{

}
.team-info{

}
.team-info-text p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;

    color: #727999;

    margin: 0;
    margin-top: 60px;
}
.team-info-text{

}
.team-info-download p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;

    color: #69F5FF;

    margin: 0;
}
.team-info-download{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;
    
    margin-top: 14px;

    cursor: pointer;
}





.team-member{
    display: flex;
    flex-direction: row;

    margin-left: -260px;
}
.team-member-description{
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-content: center;

    width: 300px;
    /* margin-right: 50px; */
    
    color: #FFFFFF;
}
.team-member-description-top{
    display: flex;
    flex-direction: column;
    align-content: space-between;
    
}
.team-member-photo{
    width: 170px;
    height: 170px;

    margin: 0;
    margin-right: 30px;
    border: 6px solid #BFF3F6;
    /* filter: drop-shadow(0px 0px 35px rgba(178, 233, 237, 0.35)); */
    /* margin-top: -10px; */
    
    /* border-radius: 80px; */

}
.musical-instrument p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.5em;
    text-transform: uppercase;

    color: #69F5FF;

    margin: 0;
}
.musical-instrument {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: -14px;
}
.team-member-name{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 110%;

    margin: 0;
    margin-top: 7px;

    color: #FFFFFF;
}
.team-member-bio{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;

    margin: 0;

    color: #FFFFFF;   
}
.team-list-mobile{
    display: none;
}

.blure-block{
    position: absolute;
    backdrop-filter: blur(2px);
    height: 250px;
    width: 250px;
    right: 0px;
    margin-top: -30px;
    z-index: 2000;
}
}






@media screen and (min-width: 200px) and (max-width: 800px){
    .team-container-wrap{
        margin-top:100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .team-container{
        width: 90%;
    }
    .team-title p{
        font-family: 'NAMU';
        font-style: normal;
        font-weight: 300;
        font-size: 32px;
        line-height: 38px;
        letter-spacing: 0.04em;

        margin: 0;
        margin-bottom: 30px;

        color: #FFFFFF;
    }
    .team-title{
    
    }
    .team-top{
        display: flex;
        flex-direction: row;
    
    }
    .team-description p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;

        color: #FFFFFF;
    }
    .team-list{
        display: none;
    }
    .team-buttons-row{
        display: none;
    }

    .team-info-text p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 180%;
        text-align: center;

        margin: 0;
        margin-bottom: 14;

        color: #727999;
    }
    .team-info-text{
    
    }
    .team-info-download p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 180%;
        
        margin: 0;

        color: #69F5FF;
    }
    .team-info-download{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 14px;
        
        margin-top: 14px;
    }
    
    
    
    .team-member-wrap{
        width: 100%;
    }
    .team-member{
        display: flex;
        flex-direction: column;
        justify-content: center;
    
    }
    .team-member-description{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
    
        
        color: #FFFFFF;
    }
    .team-member-description-top{
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        
    }
    .team-member-photo{
        margin-top:70px;

    
        width: 300px;
        height: 300px;
        margin-left:auto;
        margin-right:auto;

    
    }
    .musical-instrument p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 140%;
        text-align: center;
        letter-spacing: 0.5em;
        text-transform: uppercase;

        margin: 0;

        color: #69F5FF;
    }
    .musical-instrument {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: -6px;


    }
    .team-member-name{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.04em;

        margin: 0;
        margin-bottom: 30px;

        color: #FFFFFF;
    }
    .team-member-bio{
       font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;
        text-align: center;

        margin: 0;

        color: #FFFFFF;
    }
    .blure-block{
        display: none;
    }
}