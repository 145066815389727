
@font-face {
    font-family: "Mulish";
    src: local("Mulish"),
     url("../fonts/Mulish-Medium.ttf") format("truetype");
    font-weight: normal;
}
  
@font-face {
    font-family: "Namu";
    src: local("Namu"),
    url("../fonts/NAMU-1750.ttf") format("truetype");
    font-weight: normal;
}
  
  
@media screen and (min-width: 801px) and (max-width: 4000px){
.schedule-main-container-wrap{
    height: 100vh;
    
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;

    z-index: 100;

}
.schedule-main-container{
    width: 80%;
    z-index: 110;
}
.schedule-large-line{
    border: 1px solid #69F5FF;
    z-index: 110;
}
.schedule-medium-line{
    border: 1px solid #69F5FF;
} 
.schedule-middle{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 110;
}
.schedule-middle-left{
    width: 75%;
}
.schedule-middle-right{
    width: 50%;

    padding-left: 10%;
}
.schedule-question-first{

}
.schedule-question-first p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.5em;
    text-transform: uppercase;

    color: #69F5FF;

    margin: 0;
    margin-top: 40px;
    margin-bottom: 20px;
}
.schedule-title-first{

}
.schedule-title-first p{
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 300;
    font-size: 60px;
    line-height: 72px;
    letter-spacing: 0.03em;
    white-space: nowrap;

    margin: 0;
    margin-bottom: 40px;

    color: #FFFFFF;
}
.schedule-question-second{

}
.schedule-question-second p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.5em;
    text-transform: uppercase;

    color: #69F5FF;

    margin: 0;
    margin-top: 40px;
    margin-bottom: 20px;
}
.schedule-title-second{

}
.schedule-title-second p{
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 300;
    font-size: 60px;
    line-height: 72px;
    /* identical to box height */
    letter-spacing: 0.03em;

    margin: 0;
    margin-bottom: 40px;

    color: #FFFFFF;
}
.schedule-text-top{

}
.schedule-text-top p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 180%;

    margin: 0;
    margin-top: 20px;
    margin-bottom: 20px;

    color: #FFFFFF;
}
.schedule-text-bottom{

}
.schedule-text-bottom p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 180%;  

    color: #FFFFFF;

    margin: 0;
    margin-bottom: 20px;
}
.schedule-question-third{

}
.schedule-question-third p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.5em;

    color: #69F5FF;

    margin: 0;
    margin-top: 40px;
    margin-bottom: 20px;
}
.schedule-title-third{

}
.schedule-title-third p{
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 72px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    white-space: nowrap;

    margin: 0;
    margin-bottom: -14px;

    color: #FFFFFF;
}
.schedule-subtitle-third{

}
.schedule-subtitle-third p{
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 72px;
    letter-spacing: 0.03em;

    margin: 0;
    margin-bottom: 40px;

    color: #FFFFFF;
}
.schedule-bottom-mobile{
    display: none;
}
}




@media screen and (min-width: 200px) and (max-width: 800px){
    .schedule-main-container-wrap{
        height: 150vh;
        
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 100px;
    
        z-index: 10;
    
    }
    .schedule-main-container{
        width: 90%;
        z-index: 20;
    }
    .schedule-large-line{
        border: 1px solid #69F5FF;
        z-index: 30;
    }
    .schedule-medium-line{
        border: 1px solid #69F5FF;
        z-index: 40;
    } 
    .schedule-middle{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        z-index: 50;
    }
    .schedule-middle-left{
        width: 100%;
    }
    .schedule-middle-right{
        display: none;
    }
    .schedule-question-first{
    
    }
    .schedule-question-first p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 140%;
        letter-spacing: 0.5em;
        text-transform: uppercase;

        margin: 0;
        margin-top: 30px;
        margin-bottom: 14px;
        
        color: #69F5FF;
    }
    .schedule-title-first{
    
    }
    .schedule-title-first p{
        font-family: 'NAMU';
        font-style: normal;
        font-weight: 300;
        font-size: 32px;
        line-height: 38px;
        letter-spacing: 0.04em;

        margin: 0;
        margin-bottom: 30px;
        
        color: #FFFFFF;
    }
    .schedule-question-second{
    
    }
    .schedule-question-second p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 140%;
        letter-spacing: 0.5em;
        text-transform: uppercase;

        margin: 0;
        margin-top: 30px;
        margin-bottom: 14px;
        
        color: #69F5FF;
    }
    .schedule-title-second{
    
    }
    .schedule-title-second p{
        font-family: 'NAMU';
        font-style: normal;
        font-weight: 300;
        font-size: 32px;
        line-height: 38px;
        letter-spacing: 0.04em;
        
        margin: 0;
        margin-bottom: 30px;

        color: #FFFFFF;
    }
    .schedule-text-top{
        ;
    }
    .schedule-text-top p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;
        
        width: 90%;
        margin: 0;
        margin-top: 40px;

        color: #FFFFFF;
        
    }
    .schedule-text-bottom{
    
    }
    .schedule-text-bottom p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 180%;
        
        width: 90%;
        margin: 0;
        margin-top: 30px;
        
        color: #717999;
    }
    .schedule-question-third{
    
    }
    .schedule-question-third p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 140%;
        letter-spacing: 0.5em;
        text-transform: uppercase;

        margin: 0;
        margin-top: 30px;
        margin-bottom: 14px;
        
        color: #69F5FF;
    }
    .schedule-title-third{
    
    }
    .schedule-title-third p{
        font-family: 'NAMU';
        font-style: normal;
        font-weight: 300;
        font-size: 32px;
        line-height: 38px;
        letter-spacing: 0.04em;

        margin: 0;
        
        color: #FFFFFF;
    }
    .schedule-subtitle-third{
    
    }
    .schedule-subtitle-third p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;

        margin: 0;
        margin-bottom: 30px;
        margin-top: 14px;
        
        color: #FFFFFF;
    }
    
}