
@font-face {
    font-family: "Mulish";
    src: local("Mulish"),
     url("../fonts/Mulish-Medium.ttf") format("truetype");
    font-weight: normal;
}
  
@font-face {
    font-family: "Namu";
    src: local("Namu"),
    url("../fonts/NAMU-1750.ttf") format("truetype");
    font-weight: normal;
}
  
  
@media screen and (min-width: 801px) and (max-width: 4000px){
.bottom-row-wrap{
    display: flex;
    justify-content: center;
}
.bottom-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 80%;
}
.bottom-copyright p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;

    color: #FFFFFF;
}
.bottom-files{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    z-index: 2000;
}
.bottom-file-link p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;

    margin-left: 10px;
    margin-right: 10px;

    color: #FFFFFF;
}
.bottom-logo p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;

    color: #FFFFFF;
}
}



@media screen and (min-width: 200px) and (max-width: 800px){
    .bottom-row-wrap{
        margin-top: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
    }
    .bottom-row{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    
        width: 90%;
    }
    .bottom-copyright{
        order: 1;
    }
    .bottom-copyright p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 180%;
    
        color: #FFFFFF;
    }
    .bottom-files{
        display: flex;
        flex-direction: column;
        gap: 14px;
        order: 0;

        margin-bottom: 30px;
    }
    .bottom-file-link{
        cursor: pointer;
    }
    .bottom-file-link p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 180%;
        
        margin: 0;

        color: #FFFFFF;
    }
    .bottom-logo{
        order: 2;
    }
    .bottom-logo p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 180%;

        color: #FFFFFF;
        margin-right: 0;
        text-align: right;
    }
}
.evdev-section {
    display: flex;
    gap: 10px;
    color: white;
    font-family: "Mulish";
}
.evdev-section img{
    margin-top:10px;
    height: 30px;
}