
@font-face {
    font-family: "Mulish";
    src: local("Mulish"),
     url("../fonts/Mulish-Medium.ttf") format("truetype");
    font-weight: normal;
}
  
@font-face {
    font-family: "Namu";
    src: local("Namu"),
    url("../fonts/NAMU-1750.ttf") format("truetype");
    font-weight: normal;
}
  
  
@media screen and (min-width: 801px) and (max-width: 4000px){
.about-container-wrap{
    width: 100%;
    height: 90vh;
    margin: auto;

    display: flex;
    justify-content: space-between;
    align-items: center;

}
.about-container{
    width: 40%;
    margin-left:10%;
    
}
.about-title p{
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 300;
    font-size: 60px;
    line-height: 72px;
    letter-spacing: 0.04em;

    margin: 0;
    margin-bottom: 60px;

    color: #FFFFFF;
}
.about-title{

}
.about-main-text p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 180%;

    margin: 0;
    margin-bottom: 18px;

    color: #FFFFFF;
}
.about-main-text{

}
.about-secondary-text p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;

    margin: 0;
    margin-bottom: 60px;

    color: #727999;
}
.about-secondary-text{

}
.about-bottom-text p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;

    margin: 0;
    margin-bottom: 14px;

    color: #FFFFFF;
}
.about-bottom-text{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
}
.about-button p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;

    color: #FFFFFF;
}
.about-button-wrap{
    display: flex;
    justify-content: center;
}
.about-button{
    animation: onHoverAnimation1 0.7s reverse;
    /* background: linear-gradient(108.0deg, #B2E9ED 0.0%, #1A1F75 127.0%); */
    -webkit-animation-fill-mode: forwards;
    border-radius: 100px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;

    cursor: pointer;
    z-index: 200;
}
.about-button:hover{
    background: linear-gradient(220.0deg, #B2E9ED -56.99999999999999%, #1A1F75 232.0%);
    animation: onHoverAnimation 0.7s;
    -webkit-animation-fill-mode: forwards;
}
.about-button-shadow{
    width: 80%;
    height: 50px;
    background: linear-gradient(108.39deg, #B2E9ED 0.12%, #1A1F75 127.03%);
    filter: blur(40px);
    margin-top: -40px;
    margin-left: 10%;
    z-index: 100;
}
.notes-container{
    margin-top: 300px;
    width: 600px;
    height: 900px;
    justify-content: center;
    z-index: 20;
}
.about-button-wrap{
    display: flex;
    flex-direction: column;
}
.img-splash{
    margin-top: 200px;
    height: 700px;
    position: absolute;
    right: 0px;
    z-index: -10;
}
.notes-group-about {
    height: 350px;
    position: absolute;
    right: 0px;
    margin-top: 300px;
    z-index: 20;
}
.planet-about{
    position: absolute;
    margin-left: -10%;
    margin-top: -100px;
}
}




@media screen and (min-width: 200px) and (max-width: 800px){
    .about-container-wrap{
        width: 100%;
        height: 170vh;
        margin: auto;
        max-height: 1200px;
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        /* padding-top:100px; */
        
    }
    .about-container{
        width: 90%;
        /* margin-top: 500px; */
        z-index: 50;
    }
    .about-title p{
        font-family: 'NAMU';
        font-style: normal;
        font-weight: 300;
        font-size: 32px;
        line-height: 38px;
        letter-spacing: 0.04em;

        margin-bottom: 30px;

        color: #FFFFFF;
    }
    .about-title{
    
    }
    .about-main-text p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;

        width: 90%;
        margin: 0;
        margin-bottom: 14px;
        
        color: #FFFFFF;
        z-index: 50;
    }
    .about-main-text{
        width: 100% ;
    }
    .about-secondary-text p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 180%;
        
        width: 90%;
        margin: 0;
        margin-bottom: 44px;

        color: #727999;
    }
    .about-secondary-text{
    
    }
    .about-bottom-text p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 180%;
        
        margin: 0;
        margin-bottom: 14px;
        
        color: #FFFFFF;
    }
    .about-bottom-text{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
    }
    .about-button-wrap{
        display: flex;
        flex-direction: column;
    }
    .about-button p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
    
        color: #FFFFFF;
    }
    .about-button{
        animation: onHoverAnimation1 0.7s reverse;
        /* background: linear-gradient(108.0deg, #B2E9ED 0.0%, #1A1F75 127.0%); */
        -webkit-animation-fill-mode: forwards;
        border-radius: 100px;
    
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 60px;

        z-index: 200;
    }
    .about-button:hover{
        background: linear-gradient(220.0deg, #B2E9ED -56.99999999999999%, #1A1F75 232.0%);
        animation: onHoverAnimation 0.7s;
        -webkit-animation-fill-mode: forwards;
    }
    .about-button-shadow{
        width: 80%;
        height: 50px;
        background: linear-gradient(108.39deg, #B2E9ED 0.12%, #1A1F75 127.03%);
        filter: blur(40px);
        margin-top: -40px;
        margin-left: 10%;
        z-index: 100;
    }
    .notes-container{
        margin-top: 0px;
        width: 600px;
        height: 600px;
        justify-content: center;
    }
    .img-splash{
        margin-top: -400px;
        height: 100px;
        position: absolute;
        right: 0px;
        z-index: 0;
    }
    .notes-group-about {
        height: 100px;
        width: 300px;
        position: absolute;
        right: 0px;
        /* margin-top: -100px; */
    }
    .planet-about{
        position: absolute;
        margin-left: 10%;
        margin-top: -100px;
    }

}



@media not all and (min-resolution:.001dpcm) { @supports (-webkit-appearance:none) and (stroke-color:transparent) { 
    .about-button-shadow{
        display: none !important; 
     }
 } }