
@font-face {
    font-family: "Mulish";
    src: local("Mulish"),
     url("../fonts/Mulish-Medium.ttf") format("truetype");
    font-weight: normal;
}
  
@font-face {
    font-family: "Namu";
    src: local("Namu"),
    url("../fonts/NAMU-1750.ttf") format("truetype");
    font-weight: normal;
}
  
  
@media screen and (min-width: 801px) and (max-width: 4000px){
.application-wrap{  

    display: flex;
    justify-content: center;
    align-items: center;
    
}
.application{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 1px solid #69F5FF;

    border-radius: 40px;
    width: 598px;
    height: 777px;
    margin-top: 322px;
    margin-bottom: 500px;
}
.application-title{

}
.application-title p{
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 300;
    font-size: 60px;
    line-height: 72px;
    letter-spacing: 0.04em;
    filter: none;

    margin: 0;
    margin-top: 120px;
    margin-bottom: 60px;

    color: #FFFFFF;
}
.application-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}
.application-form-lable{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;

    color: #69F5FF;
}
.checkmark{
    border: 1px solid #69F5FF;
}
.application-button-wrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
}
.application-form-submit{
    background: linear-gradient(108.39deg, #B2E9ED 0.12%, #1A1F75 127.03%);
    border: none;
    border-radius: 100px;

    width: 280px;
    height: 60px;

    margin: 0;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    
    color: #FFFFFF;
    z-index: 20;

    cursor: pointer;
}
.application-form-submit:hover{
    background: linear-gradient(220.0deg, #B2E9ED -56.99999999999999%, #1A1F75 232.0%);
    animation: onHoverAnimation 0.7s;
    -webkit-animation-fill-mode: forwards;
}
.application-button-shadow{
    width: 70%;
    height: 50px;
    background: linear-gradient(108.39deg, #B2E9ED 0.12%, #1A1F75 127.03%);
    filter: blur(40px);
    margin-top: -40px;
    margin-left: 15%;
}
.application-form-input{               
    padding-left: 20px; 
    color: #FFFFFF;

    border: 1px solid #69F5FF;
    border-radius: 40px;

    
    height: 71px;
    width: 414px;

    background-color: #0B0426;
    z-index: 1000;
} 
.input-class{
    background-color: #0B0426;
}

input[type="checkbox"] {
    appearance: none;
    background-color: #0B0426;
    margin: 0;
    font: #69F5FF;
    color: #69F5FF;
    width: 20px;
    height: 20px;
    border: 1px solid #6BD5E9;
    border-radius: 0.15em;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;
    cursor: pointer;
    z-index: 20;
  }

input[type="checkbox"]::before {
    content: "";
    width: 20px;
    height: 20px;
    clip-path: polygon(27% 38%, 41% 63%, 75% 28%, 74% 38%, 40% 78%, 25% 50%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    background-color: #69F5FF;
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
  
  .application-confidentiality-policy{
      height: 100px;
      width: auto;
      cursor: pointer;
       z-index: 2000;
       display: flex;
       flex-direction: column;
       /* margin-top:10px; */
  }
  .application-confidentiality-policy a {
      color: white;
      font-family: 'Mulish';
      text-align: center;
      border-bottom: 1px solid white;
      padding-bottom: 5px;
      font-size: 14px;
      margin-top: -5px;
      cursor: pointer;
  }
  .container-class .react-tel-input{
    display: flex;
    justify-content: center;
    
  }
  .button-filter-application{
    position: absolute;
    height: 40px;
    width: 400px;
    margin-left:50px;
    margin-top:290px;
    z-index: 1;
    background: linear-gradient(108.39deg, #B2E9ED 0.12%, #1A1F75 127.03%);
    filter: blur(10px);
  }
  .application-svg-group{
      position: absolute;
      margin-top:-743px;
      z-index: 10;
  }
  .react-tel-input .flag-dropdown.open .selected-flag{
    background: transparent !important;
    background-color: transparent !important;

    
}
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus{
    background-color: transparent !important;
    outline: none !important;
    
}
.react-tel-input .flag-dropdown.open{
    background-color: transparent !important;
    background: transparent !important;


}
.react-tel-input .selected-flag.open:before{
    border-color: none;
}
.react-tel-input .selected-flag:focus:before, .react-tel-input .selected-flag.open:before {
    border-color: none;
}
.react-tel-input .selected-flag:focus:before, .react-tel-input .selected-flag.open:before{
    border-color: none !important;
    border: none !important;

}



.react-tel-input .selected-flag:focus:before, .react-tel-input .selected-flag.open:before{
    border-color: none !important;
    border: none !important;
    box-shadow: none;
 
}
.react-tel-input .selected-flag:focus:before, .react-tel-input .selected-flag.open:before{
    border-color: none !important;
    border: none !important;

}



.react-tel-input .country-list .country.highlight{
    background-color: transparent !important;
}

.react-tel-input .country-list .country:hover{
    background-color: transparent !important;
    color: grey;

}

.react-tel-input .selected-flag:focus:before, .react-tel-input .selected-flag.open:before{
    box-shadow: none;
}

.field-warning{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 180%;
    margin-top: -20px;
    margin-bottom: -20px;


    color: #69F5FF;
}
.field-warning-checkbox{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 180%;
    margin-top: -35px;
    margin-bottom: -20px;


    color: #69F5FF;

}

}







@media screen and (min-width: 200px) and (max-width: 800px){
    .application-wrap{  
        height: 200vh;
        max-height: 850px;
        display: flex;
        justify-content: center;
        align-items: center;
        
        z-index: 4;
    }
    .application{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
        border: 1px solid #69F5FF;
    
        border-radius: 40px;
        width: 90%;

        z-index: 6;
    }
    .application-title{
        z-index: 8;
    }
    .application-title p{
        font-family: 'NAMU';
        font-style: normal;
        font-weight: 300;
        font-size: 32px;
        line-height: 38px;
        letter-spacing: 0.04em;
        filter: none;
    
        margin: 0;
        margin-top: 60px;
        margin-bottom: 60px;
    
        color: #FFFFFF;
    }
    .application-form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
    }
    .application-form-lable{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 14px;
    
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 180%;
    
        color: #69F5FF;
    }
    .checkmark{
        border: 1px solid #69F5FF;
    }
    .button-wrap{
        display: flex;
        flex-direction: column;
    }
    .application-form-submit{
        background: linear-gradient(108.39deg, #B2E9ED 0.12%, #1A1F75 127.03%);
        border: none;
        border-radius: 100px;
    
        width: 280px;
        height: 60px;
    
        margin: auto;
    
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        
        color: #FFFFFF;
        z-index: -10;
    }
    .application-form-submit:hover{
        background: linear-gradient(220.0deg, #B2E9ED -56.99999999999999%, #1A1F75 232.0%);
        animation: onHoverAnimation 0.7s;
        -webkit-animation-fill-mode: forwards;
    }
    .application-button-shadow{
        width: 70%;
        height: 50px;
        background: linear-gradient(108.39deg, #B2E9ED 0.12%, #1A1F75 127.03%);
        filter: blur(40px);
        margin-top: -40px;
        margin-left: 15%;
        z-index: -200;
    }
    .application-form-input{               
        padding-left: 20px; 
        color: #FFFFFF;
    
        border: 1px solid #69F5FF;
        border-radius: 40px;
    
        height: 60px;
        width: 280px;

        background-color: #0B0426;
    } 
    .input-class{
        background-color: #0B0426;
    }
    .application-form-checkbox-text{
        width: 180px;
    }
    input[type="checkbox"] {
        appearance: none;
        background-color: #0B0426;
        margin: 0;
        font: #69F5FF;
        color: #69F5FF;
        width: 20px;
        height: 20px;
        border: 1px solid #6BD5E9;
        border-radius: 0.15em;
        transform: translateY(-0.075em);
    
        display: grid;
        place-content: center;
        cursor: pointer;
    

      }
    
    input[type="checkbox"]::before {
        content: "";
        width: 20px;
        height: 20px;
        clip-path: polygon(27% 38%, 41% 63%, 75% 28%, 74% 38%, 40% 78%, 25% 50%);
        transform: scale(0);
        transform-origin: bottom left;
        transition: 120ms transform ease-in-out;

        background-color: #69F5FF;
      }
    
      input[type="checkbox"]:checked::before {
        transform: scale(1);
      }
      
      .application-confidentiality-policy{
          margin-top: -30px;
          height: 45px;
          width: auto;
          cursor: pointer;
          z-index: 2000;
      }
      .application-confidentiality-policy a {
        color: white;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 180%;
        cursor: pointer;
        text-align: center;
      }
      .button-filter-application{
        position: absolute;
        height: 40px;
        width: 400px;
        margin-left:50px;
        margin-top:290px;
        z-index: 1;
        background: linear-gradient(108.39deg, #B2E9ED 0.12%, #1A1F75 127.03%);
        filter: blur(10px);
      }
    .application-svg-group{
        display: none;
    }
    .music-meteor-application-dynamic{
        z-index: -2;
    }
    /* .selected-flag:hover{
        background: transparent !important;
    } */
    .react-tel-input .flag-dropdown.open .selected-flag{
        background: transparent !important;
        background-color: transparent !important;
    
        
    }
    .react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus{
        background-color: transparent !important;
        outline: none !important;
        
    }
    .react-tel-input .flag-dropdown.open{
        background-color: transparent !important;
        background: transparent !important;
    
    
    }
    .react-tel-input .selected-flag.open:before{
        border-color: none;
    }
    .react-tel-input .selected-flag:focus:before, .react-tel-input .selected-flag.open:before {
        border-color: none;

    }
    .field-warning{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 180%;
        margin-top: -20px;
        margin-bottom: -20px;
    
    
        color: #69F5FF;
    }
    .field-warning-checkbox{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 180%;
        margin-top: -35px;
        margin-bottom: -20px;
    
    
        color: #69F5FF;
    
    }
    .form-control{
        width: 304px !important;
    }
    .flag-dropdown {
        margin-left: -80% !important;
    }
}



@media not all and (min-resolution:.001dpcm) { @supports (-webkit-appearance:none) and (stroke-color:transparent) { 
    .application-button-shadow{
        display: none !important; 
     }
 } }