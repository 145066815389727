
@font-face {
    font-family: "Mulish";
    src: local("Mulish"),
     url("../fonts/Mulish-Medium.ttf") format("truetype");
    font-weight: normal;
}
  
@font-face {
    font-family: "Namu";
    src: local("Namu"),
    url("../fonts/NAMU-1750.ttf") format("truetype");
    font-weight: normal;
}
  
  
@media screen and (min-width: 801px) and (max-width: 4000px){
.contact-info-wrap{
    height: 80vh;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:0px;
}
.contact-info{
    display: flex;
    flex-direction: column;
    gap: 10%;

    width: 1160px;
}
.contact-info-top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.contact-info-left{
    display: flex;
    flex-direction: column;
    width: 30%;
}
.contact-info-description p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;

    margin: 0;

    color: #FFFFFF;
}
.contact-info-description {
    display: flex;
    flex-direction: column;

    
}
.contacts-button-wrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    width: 100%;
}
.call-order p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;

    margin: 0;
    margin-left: 14px;

    color: #FFFFFF;
}
.call-order{
    background: linear-gradient(108.39deg, #B2E9ED 0.12%, #1A1F75 127.03%);
    border-radius: 100px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70px;

    margin-top: 40px;

    cursor: pointer;
    z-index: 200;
}
.call-order:hover{
    background: linear-gradient(220.0deg, #B2E9ED -56.99999999999999%, #1A1F75 232.0%);
    animation: onHoverAnimation 0.7s;
    -webkit-animation-fill-mode: forwards;
}
.contacts-button-shadow{
    width: 80%;
    height: 50px;
    background: linear-gradient(108.39deg, #B2E9ED 0.12%, #1A1F75 127.03%);
    filter: blur(40px);
    margin-top: -40px;
    z-index: 100;
    margin-left: 10%;
}
.contact-info-right{

}
.contact-info-subtitle p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.5em;
    text-transform: uppercase;

    color: #69F5FF;

    margin: 0;
}
.contact-info-subtitle{

}
.contact-info-title p{
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 300;
    font-size: 45px;
    line-height: 72px;
    letter-spacing: 0.04em;

    margin: 0;
    margin-top: 6px;
    margin-bottom: 20px;

    color: #FFFFFF;
}
.contact-info-title{

}
.social-networks{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-top: 100px;
}
.social-network-item p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;

    margin: 0;
    margin-left: 14px;

    color: #FFFFFF;
    mix-blend-mode: none;
}
.social-network-item{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border: 1px solid #FFFFFF;
    border-radius: 200px;

    height: 50px;
    width: 200px;

    cursor: pointer;
}
.social-networks-mobile{
    display: none;
}
a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }
}







@media screen and (min-width: 200px) and (max-width: 800px){
    .contact-info-wrap{
        height: 100vh;
    
        display: flex;
        justify-content: center;
        align-items: center;
        
    }
    .contact-info{
        display: flex;
        flex-direction: column-reverse;
        gap: 10%;
    
        width: 90%;
    }
    .contact-info-top{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .contact-info-left{
        display: flex;
        flex-direction: column;
        order: 2;
    }
    .contact-info-description p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;
    
        margin: 0;
    
        color: #FFFFFF;
    }
    .contact-info-description {
        display: flex;
        flex-direction: column;
    
        
    }
    .call-order p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
    
        margin: 0;
        margin-left: 14px;
    
        color: #FFFFFF;
    }
    .call-order{
        background: linear-gradient(108.39deg, #B2E9ED 0.12%, #1A1F75 127.03%);
        border-radius: 100px;
    
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 60px;
    
        margin-top: 30px;
        z-index: 200;
    }
    .call-order:hover{
        background: linear-gradient(220.0deg, #B2E9ED -56.99999999999999%, #1A1F75 232.0%);
        animation: onHoverAnimation 0.7s;
        -webkit-animation-fill-mode: forwards;
    }
    .contacts-button-shadow{
        width: 80%;
        height: 50px;
        background: linear-gradient(108.39deg, #B2E9ED 0.12%, #1A1F75 127.03%);
        filter: blur(40px);
        margin-top: -40px;
        z-index: 100;
        margin-left: 10%;
    }
    .contact-info-right{
        order: 0;
    }
    .contact-info-subtitle p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 140%;
        letter-spacing: 0.5em;
        text-transform: uppercase;
    
        color: #69F5FF;
    
        margin: 0;
    }
    .contact-info-subtitle{
    
    }
    .contact-info-title p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.04em;
    
        margin: 0;
        margin-top: 6px;
        margin-bottom: 30px;
    
        color: #FFFFFF;
    }
    .contact-info-title{
    
    }
    .social-networks-mobile{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        order: 1;
        width: 100%;
        margin-bottom: 60px;
    }
    .social-networks{
        display: none;
    }
    .social-network-item p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
    
        margin: 0;
        margin-left: 14px;
    
        color: #FFFFFF;
        mix-blend-mode: none;
    }
    .social-networks-mobile a{
        width: 100%;
    }
    .social-network-item{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    
        border: 1px solid #FFFFFF;
        border-radius: 200px;
        
        height: 60px;
        width: 100%;

        margin-top: 30px;
    }
    a:link { text-decoration: none; }
    a:visited { text-decoration: none; }
    a:hover { text-decoration: none; }
    a:active { text-decoration: none; }
}


@media not all and (min-resolution:.001dpcm) { @supports (-webkit-appearance:none) and (stroke-color:transparent) { 
    .contacts-button-shadow{
        display: none !important; 
     }
 } }