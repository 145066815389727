@keyframes onHoverAnimation {
    0%{
        background: linear-gradient(108.0deg, #B2E9ED 0.0%, #1A1F75 127.0%);
        }
        1%{
        background: linear-gradient(109.12deg, #B2E9ED -0.57%, #1A1F75 128.05%);
        }
        2%{
        background: linear-gradient(110.24deg, #B2E9ED -1.14%, #1A1F75 129.1%);
        }
        3%{
        background: linear-gradient(111.36deg, #B2E9ED -1.71%, #1A1F75 130.15%);
        }
        4%{
        background: linear-gradient(112.48deg, #B2E9ED -2.28%, #1A1F75 131.2%);
        }
        5%{
        background: linear-gradient(113.6deg, #B2E9ED -2.8499999999999996%, #1A1F75 132.25%);
        }
        6%{
        background: linear-gradient(114.72deg, #B2E9ED -3.42%, #1A1F75 133.3%);
        }
        7%{
        background: linear-gradient(115.84deg, #B2E9ED -3.9899999999999998%, #1A1F75 134.35%);
        }
        8%{
        background: linear-gradient(116.96000000000001deg, #B2E9ED -4.56%, #1A1F75 135.4%);
        }
        9%{
        background: linear-gradient(118.08deg, #B2E9ED -5.13%, #1A1F75 136.45%);
        }
        10%{
        background: linear-gradient(119.2deg, #B2E9ED -5.699999999999999%, #1A1F75 137.5%);
        }
        11%{
        background: linear-gradient(120.32deg, #B2E9ED -6.27%, #1A1F75 138.55%);
        }
        12%{
        background: linear-gradient(121.44deg, #B2E9ED -6.84%, #1A1F75 139.6%);
        }
        13%{
        background: linear-gradient(122.56deg, #B2E9ED -7.409999999999999%, #1A1F75 140.65%);
        }
        14%{
        background: linear-gradient(123.68deg, #B2E9ED -7.9799999999999995%, #1A1F75 141.7%);
        }
        15%{
        background: linear-gradient(124.8deg, #B2E9ED -8.549999999999999%, #1A1F75 142.75%);
        }
        16%{
        background: linear-gradient(125.92deg, #B2E9ED -9.12%, #1A1F75 143.8%);
        }
        17%{
        background: linear-gradient(127.04deg, #B2E9ED -9.69%, #1A1F75 144.85%);
        }
        18%{
        background: linear-gradient(128.16deg, #B2E9ED -10.26%, #1A1F75 145.9%);
        }
        19%{
        background: linear-gradient(129.28deg, #B2E9ED -10.829999999999998%, #1A1F75 146.95%);
        }
        20%{
        background: linear-gradient(130.4deg, #B2E9ED -11.399999999999999%, #1A1F75 148.0%);
        }
        21%{
        background: linear-gradient(131.52deg, #B2E9ED -11.969999999999999%, #1A1F75 149.05%);
        }
        22%{
        background: linear-gradient(132.64deg, #B2E9ED -12.54%, #1A1F75 150.1%);
        }
        23%{
        background: linear-gradient(133.76deg, #B2E9ED -13.11%, #1A1F75 151.15%);
        }
        24%{
        background: linear-gradient(134.88deg, #B2E9ED -13.68%, #1A1F75 152.2%);
        }
        25%{
        background: linear-gradient(136.0deg, #B2E9ED -14.249999999999998%, #1A1F75 153.25%);
        }
        26%{
        background: linear-gradient(137.12deg, #B2E9ED -14.819999999999999%, #1A1F75 154.3%);
        }
        27%{
        background: linear-gradient(138.24deg, #B2E9ED -15.389999999999999%, #1A1F75 155.35%);
        }
        28%{
        background: linear-gradient(139.36deg, #B2E9ED -15.959999999999999%, #1A1F75 156.4%);
        }
        29%{
        background: linear-gradient(140.48000000000002deg, #B2E9ED -16.529999999999998%, #1A1F75 157.45%);
        }
        30%{
        background: linear-gradient(141.6deg, #B2E9ED -17.099999999999998%, #1A1F75 158.5%);
        }
        31%{
        background: linear-gradient(142.72deg, #B2E9ED -17.669999999999998%, #1A1F75 159.55%);
        }
        32%{
        background: linear-gradient(143.84deg, #B2E9ED -18.24%, #1A1F75 160.6%);
        }
        33%{
        background: linear-gradient(144.96deg, #B2E9ED -18.81%, #1A1F75 161.65%);
        }
        34%{
        background: linear-gradient(146.08deg, #B2E9ED -19.38%, #1A1F75 162.7%);
        }
        35%{
        background: linear-gradient(147.2deg, #B2E9ED -19.95%, #1A1F75 163.75%);
        }
        36%{
        background: linear-gradient(148.32deg, #B2E9ED -20.52%, #1A1F75 164.8%);
        }
        37%{
        background: linear-gradient(149.44deg, #B2E9ED -21.09%, #1A1F75 165.85%);
        }
        38%{
        background: linear-gradient(150.56deg, #B2E9ED -21.659999999999997%, #1A1F75 166.9%);
        }
        39%{
        background: linear-gradient(151.68deg, #B2E9ED -22.229999999999997%, #1A1F75 167.95%);
        }
        40%{
        background: linear-gradient(152.8deg, #B2E9ED -22.799999999999997%, #1A1F75 169.0%);
        }
        41%{
        background: linear-gradient(153.92000000000002deg, #B2E9ED -23.369999999999997%, #1A1F75 170.05%);
        }
        42%{
        background: linear-gradient(155.04000000000002deg, #B2E9ED -23.939999999999998%, #1A1F75 171.1%);
        }
        43%{
        background: linear-gradient(156.16deg, #B2E9ED -24.509999999999998%, #1A1F75 172.15%);
        }
        44%{
        background: linear-gradient(157.28deg, #B2E9ED -25.08%, #1A1F75 173.2%);
        }
        45%{
        background: linear-gradient(158.4deg, #B2E9ED -25.65%, #1A1F75 174.25%);
        }
        46%{
        background: linear-gradient(159.52deg, #B2E9ED -26.22%, #1A1F75 175.3%);
        }
        47%{
        background: linear-gradient(160.64000000000001deg, #B2E9ED -26.79%, #1A1F75 176.35%);
        }
        48%{
        background: linear-gradient(161.76deg, #B2E9ED -27.36%, #1A1F75 177.4%);
        }
        49%{
        background: linear-gradient(162.88deg, #B2E9ED -27.929999999999996%, #1A1F75 178.45%);
        }
        50%{
        background: linear-gradient(164.0deg, #B2E9ED -28.499999999999996%, #1A1F75 179.5%);
        }
        51%{
        background: linear-gradient(165.12deg, #B2E9ED -29.069999999999997%, #1A1F75 180.55%);
        }
        52%{
        background: linear-gradient(166.24deg, #B2E9ED -29.639999999999997%, #1A1F75 181.6%);
        }
        53%{
        background: linear-gradient(167.36deg, #B2E9ED -30.209999999999997%, #1A1F75 182.65%);
        }
        54%{
        background: linear-gradient(168.48000000000002deg, #B2E9ED -30.779999999999998%, #1A1F75 183.7%);
        }
        55%{
        background: linear-gradient(169.60000000000002deg, #B2E9ED -31.349999999999998%, #1A1F75 184.75%);
        }
        56%{
        background: linear-gradient(170.72deg, #B2E9ED -31.919999999999998%, #1A1F75 185.8%);
        }
        57%{
        background: linear-gradient(171.84deg, #B2E9ED -32.489999999999995%, #1A1F75 186.85%);
        }
        58%{
        background: linear-gradient(172.96deg, #B2E9ED -33.059999999999995%, #1A1F75 187.9%);
        }
        59%{
        background: linear-gradient(174.08deg, #B2E9ED -33.629999999999995%, #1A1F75 188.95%);
        }
        60%{
        background: linear-gradient(175.2deg, #B2E9ED -34.199999999999996%, #1A1F75 190.0%);
        }
        61%{
        background: linear-gradient(176.32deg, #B2E9ED -34.769999999999996%, #1A1F75 191.05%);
        }
        62%{
        background: linear-gradient(177.44deg, #B2E9ED -35.339999999999996%, #1A1F75 192.10000000000002%);
        }
        63%{
        background: linear-gradient(178.56deg, #B2E9ED -35.91%, #1A1F75 193.15%);
        }
        64%{
        background: linear-gradient(179.68deg, #B2E9ED -36.48%, #1A1F75 194.2%);
        }
        65%{
        background: linear-gradient(180.8deg, #B2E9ED -37.05%, #1A1F75 195.25%);
        }
        66%{
        background: linear-gradient(181.92000000000002deg, #B2E9ED -37.62%, #1A1F75 196.3%);
        }
        67%{
        background: linear-gradient(183.04000000000002deg, #B2E9ED -38.19%, #1A1F75 197.35000000000002%);
        }
        68%{
        background: linear-gradient(184.16000000000003deg, #B2E9ED -38.76%, #1A1F75 198.4%);
        }
        69%{
        background: linear-gradient(185.28deg, #B2E9ED -39.33%, #1A1F75 199.45%);
        }
        70%{
        background: linear-gradient(186.4deg, #B2E9ED -39.9%, #1A1F75 200.5%);
        }
        71%{
        background: linear-gradient(187.52deg, #B2E9ED -40.47%, #1A1F75 201.55%);
        }
        72%{
        background: linear-gradient(188.64000000000001deg, #B2E9ED -41.04%, #1A1F75 202.60000000000002%);
        }
        73%{
        background: linear-gradient(189.76deg, #B2E9ED -41.61%, #1A1F75 203.65%);
        }
        74%{
        background: linear-gradient(190.88deg, #B2E9ED -42.18%, #1A1F75 204.7%);
        }
        75%{
        background: linear-gradient(192.0deg, #B2E9ED -42.74999999999999%, #1A1F75 205.75%);
        }
        76%{
        background: linear-gradient(193.12deg, #B2E9ED -43.31999999999999%, #1A1F75 206.8%);
        }
        77%{
        background: linear-gradient(194.24deg, #B2E9ED -43.88999999999999%, #1A1F75 207.85000000000002%);
        }
        78%{
        background: linear-gradient(195.36deg, #B2E9ED -44.459999999999994%, #1A1F75 208.9%);
        }
        79%{
        background: linear-gradient(196.48000000000002deg, #B2E9ED -45.029999999999994%, #1A1F75 209.95%);
        }
        80%{
        background: linear-gradient(197.60000000000002deg, #B2E9ED -45.599999999999994%, #1A1F75 211.0%);
        }
        81%{
        background: linear-gradient(198.72000000000003deg, #B2E9ED -46.169999999999995%, #1A1F75 212.05%);
        }
        82%{
        background: linear-gradient(199.84deg, #B2E9ED -46.739999999999995%, #1A1F75 213.10000000000002%);
        }
        83%{
        background: linear-gradient(200.96deg, #B2E9ED -47.309999999999995%, #1A1F75 214.15%);
        }
        84%{
        background: linear-gradient(202.08deg, #B2E9ED -47.879999999999995%, #1A1F75 215.2%);
        }
        85%{
        background: linear-gradient(203.2deg, #B2E9ED -48.449999999999996%, #1A1F75 216.25%);
        }
        86%{
        background: linear-gradient(204.32deg, #B2E9ED -49.019999999999996%, #1A1F75 217.3%);
        }
        87%{
        background: linear-gradient(205.44deg, #B2E9ED -49.589999999999996%, #1A1F75 218.35000000000002%);
        }
        88%{
        background: linear-gradient(206.56deg, #B2E9ED -50.16%, #1A1F75 219.4%);
        }
        89%{
        background: linear-gradient(207.68deg, #B2E9ED -50.73%, #1A1F75 220.45%);
        }
        90%{
        background: linear-gradient(208.8deg, #B2E9ED -51.3%, #1A1F75 221.5%);
        }
        91%{
        background: linear-gradient(209.92000000000002deg, #B2E9ED -51.87%, #1A1F75 222.55%);
        }
        92%{
        background: linear-gradient(211.04000000000002deg, #B2E9ED -52.44%, #1A1F75 223.60000000000002%);
        }
        93%{
        background: linear-gradient(212.16000000000003deg, #B2E9ED -53.01%, #1A1F75 224.65%);
        }
        94%{
        background: linear-gradient(213.28000000000003deg, #B2E9ED -53.58%, #1A1F75 225.7%);
        }
        95%{
        background: linear-gradient(214.4deg, #B2E9ED -54.15%, #1A1F75 226.75%);
        }
        96%{
        background: linear-gradient(215.52deg, #B2E9ED -54.72%, #1A1F75 227.8%);
        }
        97%{
        background: linear-gradient(216.64000000000001deg, #B2E9ED -55.28999999999999%, #1A1F75 228.85000000000002%);
        }
        98%{
        background: linear-gradient(217.76deg, #B2E9ED -55.85999999999999%, #1A1F75 229.9%);
        }
        99%{
        background: linear-gradient(218.88deg, #B2E9ED -56.42999999999999%, #1A1F75 230.95%);
        }
}

@keyframes onHoverAnimation1 {
    0%{
        background: linear-gradient(108.0deg, #B2E9ED 0.0%, #1A1F75 127.0%);
        }
        1%{
        background: linear-gradient(109.12deg, #B2E9ED -0.57%, #1A1F75 128.05%);
        }
        2%{
        background: linear-gradient(110.24deg, #B2E9ED -1.14%, #1A1F75 129.1%);
        }
        3%{
        background: linear-gradient(111.36deg, #B2E9ED -1.71%, #1A1F75 130.15%);
        }
        4%{
        background: linear-gradient(112.48deg, #B2E9ED -2.28%, #1A1F75 131.2%);
        }
        5%{
        background: linear-gradient(113.6deg, #B2E9ED -2.8499999999999996%, #1A1F75 132.25%);
        }
        6%{
        background: linear-gradient(114.72deg, #B2E9ED -3.42%, #1A1F75 133.3%);
        }
        7%{
        background: linear-gradient(115.84deg, #B2E9ED -3.9899999999999998%, #1A1F75 134.35%);
        }
        8%{
        background: linear-gradient(116.96000000000001deg, #B2E9ED -4.56%, #1A1F75 135.4%);
        }
        9%{
        background: linear-gradient(118.08deg, #B2E9ED -5.13%, #1A1F75 136.45%);
        }
        10%{
        background: linear-gradient(119.2deg, #B2E9ED -5.699999999999999%, #1A1F75 137.5%);
        }
        11%{
        background: linear-gradient(120.32deg, #B2E9ED -6.27%, #1A1F75 138.55%);
        }
        12%{
        background: linear-gradient(121.44deg, #B2E9ED -6.84%, #1A1F75 139.6%);
        }
        13%{
        background: linear-gradient(122.56deg, #B2E9ED -7.409999999999999%, #1A1F75 140.65%);
        }
        14%{
        background: linear-gradient(123.68deg, #B2E9ED -7.9799999999999995%, #1A1F75 141.7%);
        }
        15%{
        background: linear-gradient(124.8deg, #B2E9ED -8.549999999999999%, #1A1F75 142.75%);
        }
        16%{
        background: linear-gradient(125.92deg, #B2E9ED -9.12%, #1A1F75 143.8%);
        }
        17%{
        background: linear-gradient(127.04deg, #B2E9ED -9.69%, #1A1F75 144.85%);
        }
        18%{
        background: linear-gradient(128.16deg, #B2E9ED -10.26%, #1A1F75 145.9%);
        }
        19%{
        background: linear-gradient(129.28deg, #B2E9ED -10.829999999999998%, #1A1F75 146.95%);
        }
        20%{
        background: linear-gradient(130.4deg, #B2E9ED -11.399999999999999%, #1A1F75 148.0%);
        }
        21%{
        background: linear-gradient(131.52deg, #B2E9ED -11.969999999999999%, #1A1F75 149.05%);
        }
        22%{
        background: linear-gradient(132.64deg, #B2E9ED -12.54%, #1A1F75 150.1%);
        }
        23%{
        background: linear-gradient(133.76deg, #B2E9ED -13.11%, #1A1F75 151.15%);
        }
        24%{
        background: linear-gradient(134.88deg, #B2E9ED -13.68%, #1A1F75 152.2%);
        }
        25%{
        background: linear-gradient(136.0deg, #B2E9ED -14.249999999999998%, #1A1F75 153.25%);
        }
        26%{
        background: linear-gradient(137.12deg, #B2E9ED -14.819999999999999%, #1A1F75 154.3%);
        }
        27%{
        background: linear-gradient(138.24deg, #B2E9ED -15.389999999999999%, #1A1F75 155.35%);
        }
        28%{
        background: linear-gradient(139.36deg, #B2E9ED -15.959999999999999%, #1A1F75 156.4%);
        }
        29%{
        background: linear-gradient(140.48000000000002deg, #B2E9ED -16.529999999999998%, #1A1F75 157.45%);
        }
        30%{
        background: linear-gradient(141.6deg, #B2E9ED -17.099999999999998%, #1A1F75 158.5%);
        }
        31%{
        background: linear-gradient(142.72deg, #B2E9ED -17.669999999999998%, #1A1F75 159.55%);
        }
        32%{
        background: linear-gradient(143.84deg, #B2E9ED -18.24%, #1A1F75 160.6%);
        }
        33%{
        background: linear-gradient(144.96deg, #B2E9ED -18.81%, #1A1F75 161.65%);
        }
        34%{
        background: linear-gradient(146.08deg, #B2E9ED -19.38%, #1A1F75 162.7%);
        }
        35%{
        background: linear-gradient(147.2deg, #B2E9ED -19.95%, #1A1F75 163.75%);
        }
        36%{
        background: linear-gradient(148.32deg, #B2E9ED -20.52%, #1A1F75 164.8%);
        }
        37%{
        background: linear-gradient(149.44deg, #B2E9ED -21.09%, #1A1F75 165.85%);
        }
        38%{
        background: linear-gradient(150.56deg, #B2E9ED -21.659999999999997%, #1A1F75 166.9%);
        }
        39%{
        background: linear-gradient(151.68deg, #B2E9ED -22.229999999999997%, #1A1F75 167.95%);
        }
        40%{
        background: linear-gradient(152.8deg, #B2E9ED -22.799999999999997%, #1A1F75 169.0%);
        }
        41%{
        background: linear-gradient(153.92000000000002deg, #B2E9ED -23.369999999999997%, #1A1F75 170.05%);
        }
        42%{
        background: linear-gradient(155.04000000000002deg, #B2E9ED -23.939999999999998%, #1A1F75 171.1%);
        }
        43%{
        background: linear-gradient(156.16deg, #B2E9ED -24.509999999999998%, #1A1F75 172.15%);
        }
        44%{
        background: linear-gradient(157.28deg, #B2E9ED -25.08%, #1A1F75 173.2%);
        }
        45%{
        background: linear-gradient(158.4deg, #B2E9ED -25.65%, #1A1F75 174.25%);
        }
        46%{
        background: linear-gradient(159.52deg, #B2E9ED -26.22%, #1A1F75 175.3%);
        }
        47%{
        background: linear-gradient(160.64000000000001deg, #B2E9ED -26.79%, #1A1F75 176.35%);
        }
        48%{
        background: linear-gradient(161.76deg, #B2E9ED -27.36%, #1A1F75 177.4%);
        }
        49%{
        background: linear-gradient(162.88deg, #B2E9ED -27.929999999999996%, #1A1F75 178.45%);
        }
        50%{
        background: linear-gradient(164.0deg, #B2E9ED -28.499999999999996%, #1A1F75 179.5%);
        }
        51%{
        background: linear-gradient(165.12deg, #B2E9ED -29.069999999999997%, #1A1F75 180.55%);
        }
        52%{
        background: linear-gradient(166.24deg, #B2E9ED -29.639999999999997%, #1A1F75 181.6%);
        }
        53%{
        background: linear-gradient(167.36deg, #B2E9ED -30.209999999999997%, #1A1F75 182.65%);
        }
        54%{
        background: linear-gradient(168.48000000000002deg, #B2E9ED -30.779999999999998%, #1A1F75 183.7%);
        }
        55%{
        background: linear-gradient(169.60000000000002deg, #B2E9ED -31.349999999999998%, #1A1F75 184.75%);
        }
        56%{
        background: linear-gradient(170.72deg, #B2E9ED -31.919999999999998%, #1A1F75 185.8%);
        }
        57%{
        background: linear-gradient(171.84deg, #B2E9ED -32.489999999999995%, #1A1F75 186.85%);
        }
        58%{
        background: linear-gradient(172.96deg, #B2E9ED -33.059999999999995%, #1A1F75 187.9%);
        }
        59%{
        background: linear-gradient(174.08deg, #B2E9ED -33.629999999999995%, #1A1F75 188.95%);
        }
        60%{
        background: linear-gradient(175.2deg, #B2E9ED -34.199999999999996%, #1A1F75 190.0%);
        }
        61%{
        background: linear-gradient(176.32deg, #B2E9ED -34.769999999999996%, #1A1F75 191.05%);
        }
        62%{
        background: linear-gradient(177.44deg, #B2E9ED -35.339999999999996%, #1A1F75 192.10000000000002%);
        }
        63%{
        background: linear-gradient(178.56deg, #B2E9ED -35.91%, #1A1F75 193.15%);
        }
        64%{
        background: linear-gradient(179.68deg, #B2E9ED -36.48%, #1A1F75 194.2%);
        }
        65%{
        background: linear-gradient(180.8deg, #B2E9ED -37.05%, #1A1F75 195.25%);
        }
        66%{
        background: linear-gradient(181.92000000000002deg, #B2E9ED -37.62%, #1A1F75 196.3%);
        }
        67%{
        background: linear-gradient(183.04000000000002deg, #B2E9ED -38.19%, #1A1F75 197.35000000000002%);
        }
        68%{
        background: linear-gradient(184.16000000000003deg, #B2E9ED -38.76%, #1A1F75 198.4%);
        }
        69%{
        background: linear-gradient(185.28deg, #B2E9ED -39.33%, #1A1F75 199.45%);
        }
        70%{
        background: linear-gradient(186.4deg, #B2E9ED -39.9%, #1A1F75 200.5%);
        }
        71%{
        background: linear-gradient(187.52deg, #B2E9ED -40.47%, #1A1F75 201.55%);
        }
        72%{
        background: linear-gradient(188.64000000000001deg, #B2E9ED -41.04%, #1A1F75 202.60000000000002%);
        }
        73%{
        background: linear-gradient(189.76deg, #B2E9ED -41.61%, #1A1F75 203.65%);
        }
        74%{
        background: linear-gradient(190.88deg, #B2E9ED -42.18%, #1A1F75 204.7%);
        }
        75%{
        background: linear-gradient(192.0deg, #B2E9ED -42.74999999999999%, #1A1F75 205.75%);
        }
        76%{
        background: linear-gradient(193.12deg, #B2E9ED -43.31999999999999%, #1A1F75 206.8%);
        }
        77%{
        background: linear-gradient(194.24deg, #B2E9ED -43.88999999999999%, #1A1F75 207.85000000000002%);
        }
        78%{
        background: linear-gradient(195.36deg, #B2E9ED -44.459999999999994%, #1A1F75 208.9%);
        }
        79%{
        background: linear-gradient(196.48000000000002deg, #B2E9ED -45.029999999999994%, #1A1F75 209.95%);
        }
        80%{
        background: linear-gradient(197.60000000000002deg, #B2E9ED -45.599999999999994%, #1A1F75 211.0%);
        }
        81%{
        background: linear-gradient(198.72000000000003deg, #B2E9ED -46.169999999999995%, #1A1F75 212.05%);
        }
        82%{
        background: linear-gradient(199.84deg, #B2E9ED -46.739999999999995%, #1A1F75 213.10000000000002%);
        }
        83%{
        background: linear-gradient(200.96deg, #B2E9ED -47.309999999999995%, #1A1F75 214.15%);
        }
        84%{
        background: linear-gradient(202.08deg, #B2E9ED -47.879999999999995%, #1A1F75 215.2%);
        }
        85%{
        background: linear-gradient(203.2deg, #B2E9ED -48.449999999999996%, #1A1F75 216.25%);
        }
        86%{
        background: linear-gradient(204.32deg, #B2E9ED -49.019999999999996%, #1A1F75 217.3%);
        }
        87%{
        background: linear-gradient(205.44deg, #B2E9ED -49.589999999999996%, #1A1F75 218.35000000000002%);
        }
        88%{
        background: linear-gradient(206.56deg, #B2E9ED -50.16%, #1A1F75 219.4%);
        }
        89%{
        background: linear-gradient(207.68deg, #B2E9ED -50.73%, #1A1F75 220.45%);
        }
        90%{
        background: linear-gradient(208.8deg, #B2E9ED -51.3%, #1A1F75 221.5%);
        }
        91%{
        background: linear-gradient(209.92000000000002deg, #B2E9ED -51.87%, #1A1F75 222.55%);
        }
        92%{
        background: linear-gradient(211.04000000000002deg, #B2E9ED -52.44%, #1A1F75 223.60000000000002%);
        }
        93%{
        background: linear-gradient(212.16000000000003deg, #B2E9ED -53.01%, #1A1F75 224.65%);
        }
        94%{
        background: linear-gradient(213.28000000000003deg, #B2E9ED -53.58%, #1A1F75 225.7%);
        }
        95%{
        background: linear-gradient(214.4deg, #B2E9ED -54.15%, #1A1F75 226.75%);
        }
        96%{
        background: linear-gradient(215.52deg, #B2E9ED -54.72%, #1A1F75 227.8%);
        }
        97%{
        background: linear-gradient(216.64000000000001deg, #B2E9ED -55.28999999999999%, #1A1F75 228.85000000000002%);
        }
        98%{
        background: linear-gradient(217.76deg, #B2E9ED -55.85999999999999%, #1A1F75 229.9%);
        }
        100%{
        background: linear-gradient(218.88deg, #B2E9ED -56.42999999999999%, #1A1F75 230.95%);
        }
}
