@media screen and (min-width: 801px) and (max-width: 4000px){
    header{
        height: 100px;
        width: 100vw;
        display: flex;
        justify-content: center;
        position: fixed;
        z-index: 15000;
        transition: 0.5s;
    }
    .header-container{
        width: 1160px;
    
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .header-logo{
        display: flex;
        align-items: center;
        z-index: 100;
    }
    .header-tabs{
        display: flex;
        height: 100px;
        align-items: center;
        gap: 64px;
    }
    .header-nav-buttons{
        display: flex;
        height: 100px;
        align-items: center;
        gap: 33px;
    }
    .header-nav-buttons p {
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 180%;
        cursor: pointer;
    
        color: white;
    }
    .header-change-language{
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
    }
    .language-toggler{
        border:1px solid white;
        width: 105px;;
        border-radius: 20px;
        display: flex;
        color: white;
        font-family: 'Mulish';
        height: 25px;
    }
    .select-english p, .select-ukrainian p{
        width: 40.5px;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 180%;
    
        margin: 0px;
        margin-left: 12px;
        cursor: pointer;
        color: white;
    }
    .select-english{
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }
    .select-ukrainian{
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    .noselect {
        -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
           -khtml-user-select: none; /* Konqueror HTML */
             -moz-user-select: none; /* Old versions of Firefox */
              -ms-user-select: none; /* Internet Explorer/Edge */
                  user-select: none; /* Non-prefixed version, currently
                                        supported by Chrome, Edge, Opera and Firefox */
      }
    
    
    
    .active-slide{
        filter: drop-shadow(0px 0px 5px #6BD5E9);  
        border-bottom: 1px solid white;
        text-shadow: 0px 0px 5px #FFFFFF;
    }
    .header-nav-buttons p:hover{
        filter: drop-shadow(0px 0px 5px #6BD5E9);  
        border-bottom: 1px solid white;
        text-shadow: 0px 0px 5px #FFFFFF;
    }
    .header-nav-buttons p:active{
        filter: drop-shadow(0px 0px 5px #6BD5E9);  
        border-bottom: 1px solid white;
        text-shadow: 0px 0px 20px #FFFFFF;
    }
    } 
    
    
    
    
    @media screen and (min-width: 200px) and (max-width: 800px){
        .header-tabs{
            display: none;
        }
        .header-logo{
            display: none;
        }
    
    
        .bm-burger-button1{
            z-index: 900; position: fixed; width: 40px; height: 30px;
            top:45px;
            right:25px;
            cursor: pointer;
        }

        
        .bm-burger-bars1{
            position: absolute; height: 14%; left: 0px; right: 0px; top: 0%; opacity: 1; background-color: white; border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-right-radius: 20px; border-bottom-left-radius: 20px; transition: 0.2s;
        }
        .bm-burger-bars2{
            position: absolute; height: 14%; left: 0px; right: 0px; top: 40%; opacity: 1; background-color: white; border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-right-radius: 20px; border-bottom-left-radius: 20px; transition: 0.2s;
        }
        .bm-burger-bars3{
            position: absolute; height: 14%; left: 0px; right: 0px; top: 80%; opacity: 1; background-color: white; border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-right-radius: 20px; border-bottom-left-radius: 20px; transition: 0.2s;
        }
    
        .menubuttons-mobile p{
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 200%;
    
            margin: 0;
            margin-top: 14px;
    
            color: #FFFFFF;
        }
        .menubuttons-mobile {
            margin-top: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            z-index: 15000;
        }
        .bm-menu{
            width: 100vw !important;
        }
    
        
        .language-toggler{
            border:1px solid white;
            width: 100px;
            border-radius: 20px;
            display: flex;
            color: white;
            font-family: 'Mulish';
            height: 20px;
            margin-top: 20px;
        }
        .select-english p, .select-ukrainian p{
            width: 50px;
            text-align: center;
            line-height: 20px;
            margin-top: 0px;
            margin-bottom: 0px;
            cursor: pointer;
            color: white;
        }
        .select-english{
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
        }
        .select-ukrainian{
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
        }
        .noselect {
            -webkit-touch-callout: none; /* iOS Safari */
              -webkit-user-select: none; /* Safari */
               -khtml-user-select: none; /* Konqueror HTML */
                 -moz-user-select: none; /* Old versions of Firefox */
                  -ms-user-select: none; /* Internet Explorer/Edge */
                      user-select: none; /* Non-prefixed version, currently
                                            supported by Chrome, Edge, Opera and Firefox */
          }
    }

/* 
    .mobile-blur-background{
        -webkit-backdrop-filter: blur(5px);
        width: 100vw;
        height: 100vh;
        position: fixed;
    } */