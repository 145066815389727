
@font-face {
    font-family: "Mulish";
    src: local("Mulish"),
     url("../fonts/Mulish-Medium.ttf") format("truetype");
    font-weight: normal;
}
  
@font-face {
    font-family: "Namu";
    src: local("Namu"),
    url("../fonts/NAMU-1750.ttf") format("truetype");
    font-weight: normal;
}
  
  
@media screen and (min-width: 801px) and (max-width: 4000px){

    .start-container-wrap{
        height: 100vh;
    
        display: flex;
        align-items: center;
    
        padding-top: 30vh;
        justify-content: center;
    }
.main-title-wrap{
    margin-bottom: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50px;
}
.start-container{
    width: 80%;
}
.main-title-wrap{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.main-title-container-left-top{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}
.main-title-container-right{
    margin: auto;

    background-color: none;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 0;
}
.main-ima p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 80px;
    line-height: 180%;
    margin: 0;

    color: #FFFFFF;
}
.main-ima{
    flex-grow: 0;
    height: 80px;
    
    display: flex;
    align-items: center;
    justify-content: left;
}
.main-academy p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 140%;
    letter-spacing: 0.5em;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 24px;
    margin-left: 30px;

    width: 200px;

    color: #FFFFFF;
}
.main-academy{
    flex-grow: 0;

    display: flex;
    align-items: center;
    justify-content: left;
}
.main-virtuosos p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 80px;
    line-height: 180%;
    margin: 0;
    margin-top: -30px;

    color: #FFFFFF;
}
.main-virtuosos{
    flex-grow: 1;

    display: flex;
    align-items: center;
    justify-content: left;
}
.main-button-wrap{
    flex-grow: 1;
    height: 80px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.main-button{
    animation: onHoverAnimation1 0.7s reverse;
    /* background: linear-gradient(108.0deg, #B2E9ED 0.0%, #1A1F75 127.0%); */
    -webkit-animation-fill-mode: forwards;
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;

    cursor: pointer;
    z-index: 200;
}
.main-button:hover{
    /* background: linear-gradient( #ff0000, #1A1F75); */
    background: linear-gradient(220.0deg, #B2E9ED -56.99999999999999%, #1A1F75 232.0%);
    animation: onHoverAnimation 0.7s;
    -webkit-animation-fill-mode: forwards;

}
.main-button-shadow{
    width: 80%;
    height: 50px;
    background: linear-gradient(108.39deg, #B2E9ED 0.12%, #1A1F75 127.03%);
    -webkit-filter: blur(40px);
    -moz-filter: blur(40px);
    transition: all 200ms linear;
    margin-top: -40px;
    z-index: 100;
}
.main-button-text{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    padding: 0;
    margin: 0;

    color: #FFFFFF;
}

.main-text p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    margin: 0;
    margin-bottom: 30px;
    width: 450px;

    color: #FFFFFF;
}
.main-text{
    flex-grow: 1;

    display: flex;
    align-items: center;
    justify-content: center;
}
.main-shift-button-wrap{
    display: flex;
    justify-content: center;

    margin-bottom: 100px;
}
.main-shift-button p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    text-align: center;

    color: #FFFFFF;
}
.main-shift-button {
    display: flex;
    flex-direction: column;
    justify-content: center;

    cursor: pointer;
}
.main-shift-button img{
    width: 20px;
    height: 10px;

    margin: auto;
}




.app-container{
    background-image: url('background.svg');
    background-size: cover;
    background-position: center;
    z-index: -10;
}
.up-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 114px;
    height: 46px;
    
    position: fixed;
    right: calc((100vw - 1160px) / 2 );
    top: 90vh;
    z-index: 20000;
}
.up-text{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 180%;

    color: #FFFFFF;
}
 
}

@media not all and (min-resolution:.001dpcm) { @supports (-webkit-appearance:none) and (stroke-color:transparent) { 
    .main-button-shadow{
        display: none !important; 
     }
 } }


@media screen and (min-width: 200px) and (max-width: 800px){
    .up-container{
        display: none;
    }
    .start-rock-group{
        display: none;
    }
    .start-planet{
        margin-top: -80px;
    }
    .start-container-wrap{
        height: 100vh;
    
        display: flex;
        align-items: center;
    
        justify-content: center;
        z-index: 100;
    }
    .start-container{
        margin-top: 20vh;
        width: 90%;

        z-index: 102;
    }
    .main-title-wrap{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .main-title-container-left-top{
        display: flex;
        flex-direction: row;
    }
    .main-title-container-right{
        margin: auto;
    
        background-color: none;
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: wrap;
        align-items: stretch;
        margin: 0;
    }
    .main-ima p{
        font-family: 'NAMU';
        font-style: normal;
        font-weight: 300;
        font-size: 50px;
        line-height: 100%;
        letter-spacing: 0.03em;

        margin: 0;

        color: #FFFFFF;

    }
    .main-ima{
        flex-grow: 0;
        height: 80px;
        
        display: flex;
        align-items: center;
        justify-content: left;
    }
    .main-academy p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 140%;

        display: flex;
        align-items: flex-end;
        letter-spacing: 0.5em;
        text-transform: uppercase;

        margin: 0;
        margin-left: 30px;
        margin-bottom: -10px;
        width: 200px;

        color: #FFFFFF;

    }
    .main-academy{
        flex-grow: 0;
        
    
        display: flex;
        align-items: center;
        justify-content: left;
    }
    .main-virtuosos p{
        font-family: 'NAMU';
        font-style: normal;
        font-weight: 300;
        font-size: 50px;
        line-height: 100%;
        /* identical to box height, or 50px */
        letter-spacing: 0.03em;

        margin: 0;
        margin-bottom: 30px;

        color: #FFFFFF;
    }
    .main-virtuosos{
        flex-grow: 1;
    
        display: flex;
        align-items: center;
        justify-content: left;
    }

    .main-button{
        animation: onHoverAnimation1 0.7s reverse;
        /* background: linear-gradient(108.0deg, #B2E9ED 0.0%, #1A1F75 127.0%); */
        -webkit-animation-fill-mode: forwards;
        border-radius: 100px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 60px;
        z-index: 200;
    }
    .main-button:hover{
        /* background: linear-gradient( #ff0000, #1A1F75); */
        background: linear-gradient(220.0deg, #B2E9ED -56.99999999999999%, #1A1F75 232.0%);
        animation: onHoverAnimation 0.7s;
        -webkit-animation-fill-mode: forwards;
    
    }
    .main-button-shadow{
        width: 80%;
        height: 50px;
        background: linear-gradient(108.39deg, #B2E9ED 0.12%, #1A1F75 127.03%);
        background: -webkit-linear-gradient(108.39deg, #B2E9ED 0.12%, #1A1F75 127.03%);
        filter: blur(40px);
        margin-top: -40px;
        z-index: 100;
    }
    .main-button-text{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        padding: 0;
        margin: 0;
    
        color: #FFFFFF;
    }
    
    .main-text p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;

        width: 298px;

        color: #FFFFFF;
        margin-bottom: 30px;;
    }
    .main-text{


    }
    .main-shift-button-wrap{
        display: flex;
        justify-content: center;
    
    }
    .main-shift-button p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 180%;
        text-align: center;
    
        color: #FFFFFF;
    }
    .main-shift-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .main-shift-button img{
        width: 20px;
        height: 10px;
    
        margin: auto;
    }
    
    
    
    .App{
        overflow: hidden;
    }
    .app-container{
        background-image: url('background_mobile.svg');
        background-size: cover;
        background-position: center;
        z-index: 1;
        overflow: hidden;
        position: relative;
    }
    .body{
        overflow: hidden;
    }
    .meteor-start{
        z-index: 2;
    }
    
}
