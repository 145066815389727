
@font-face {
    font-family: "Mulish";
    src: local("Mulish"),
     url("../fonts/Mulish-Medium.ttf") format("truetype");
    font-weight: normal;
}
  
@font-face {
    font-family: "Namu";
    src: local("Namu"),
    url("../fonts/NAMU-1750.ttf") format("truetype");
    font-weight: normal;
}
  
  
@media screen and (min-width: 801px) and (max-width: 4000px){
.apply-container-wrap{
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 100px;
}
.apply-container{
    width: 60%;

    display: flex;
    flex-direction: column;
    gap: 100px;
}
.apply-member{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.apply-member-title{

}
.apply-member-title p{
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 300;
    font-size: 60px;
    line-height: 72px;
    text-align: center;
    letter-spacing: 0.04em;

    margin: 0;
    margin-bottom: 60px;

    color: #FFFFFF;
}
.apply-member-text{
    display: flex;
    justify-content: center;
/* width: 100%; */
}
.apply-member{
    display: flex;
    justify-content: center;
}
.apply-member-text p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 180%;
    text-align: center;
    width: 560px;
    height: 160px;
    margin: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 60px;

    color: #FFFFFF;
}
.apply-button-wrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2000;
}
.apply-member-button{
    animation: onHoverAnimation1 0.7s reverse;
    /* background: linear-gradient(108.0deg, #B2E9ED 0.0%, #1A1F75 127.0%); */
    -webkit-animation-fill-mode: forwards;
    border-radius: 100px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 60px;

    margin: auto;
    cursor: pointer;
    z-index: 2000;
}
.apply-member-button:hover{
    background: linear-gradient(220.0deg, #B2E9ED -56.99999999999999%, #1A1F75 232.0%);
    animation: onHoverAnimation 0.7s;
    -webkit-animation-fill-mode: forwards;
}
.apply-button-shadow{
    width: 50%;
    height: 50px;
    background: linear-gradient(108.39deg, #B2E9ED 0.12%, #1A1F75 127.03%);
    filter: blur(40px);
    margin-top: -40px;
    margin-left: 25%;
    z-index: 1900;
}
.apply-member-button p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;

    color: #FFFFFF;
}
.apply-warning{
    display: flex;
    justify-content: center;
    flex-direction: column; 
}
.apply-warning-title{

}
.apply-warning-title p{
    
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 110%;
    text-align: center;

    margin: 0;
    margin-bottom: 28px;

    color: #FFFFFF;

}
.apply-warning-text{
    display: flex;
    justify-content: center;
}
.apply-warning-text p{
    width: 440px;
height: 175px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    text-align: center;

    margin: 0;
    margin-bottom: 28px;

    color: #FFFFFF;
}
.apply-warning-file{
    width: auto;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    z-index: 2000;
}
.apply-warning-file p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;

    margin: 0;
    margin-left: 14px;

    color: #69F5FF;
}
}



@media screen and (min-width: 200px) and (max-width: 800px){
    .apply-container-wrap{
        height: 140vh;
    
        display: flex;
        justify-content: center;
        align-items: center;
    
        margin-top: 100px;

        z-index: 4;
    }
    .apply-container{
        width: 90%;
    
        display: flex;
        flex-direction: column;
        gap: 100px;

        z-index: 6;
    }
    .apply-member{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .apply-member-title{
        display: flex;
        justify-content: center;
    }
    .apply-member-title p{
        font-family: 'NAMU';
        font-style: normal;
        font-weight: 300;
        font-size: 32px;
        line-height: 38px;
        text-align: center;
        letter-spacing: 0.04em;

        margin: 0;
        margin-bottom: 30px;

        width: 50%;

        

        color: #FFFFFF;

    }
    .apply-member-text{
        display: flex;
        justify-content: center;
  
    }
    .apply-member{
        display: flex;
        justify-content: center;
    }
    .apply-member-text p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;
        text-align: center;

        margin: 0;
        margin-bottom: 30px;

        display: flex;
        justify-content: center;
        

        color: #FFFFFF;
    }
    .apply-button-wrap{
        display: flex;
        flex-direction: column;
        z-index: 2000;
    }
    .apply-member-button{
        animation: onHoverAnimation1 0.7s reverse;
        /* background: linear-gradient(108.0deg, #B2E9ED 0.0%, #1A1F75 127.0%); */
        -webkit-animation-fill-mode: forwards;
        border-radius: 100px;
    
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 60px;
    
        margin: 0;
        margin-bottom: -20px;
        cursor: pointer;
        z-index: 2100;
    }
    .apply-member-button:hover{
        background: linear-gradient(220.0deg, #B2E9ED -56.99999999999999%, #1A1F75 232.0%);
        animation: onHoverAnimation 0.7s;
        -webkit-animation-fill-mode: forwards;
    }
    .apply-button-shadow{
        width: 90%;
        height: 50px;
        background: linear-gradient(108.39deg, #B2E9ED 0.12%, #1A1F75 127.03%);
        filter: blur(40px);
        margin-top: -30px;
        margin-left: 10%;
        z-index: 1900;
    }
    .apply-member-button p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
    
        color: #FFFFFF;
    }
    .apply-warning{
        display: flex;
        justify-content: center;
        flex-direction: column; 
    }
    .apply-warning-title{
    
    }
    .apply-warning-title p{   
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
    
        margin: 0;
        margin-bottom: 30px;
    
        color: #FFFFFF;
    }
    .apply-warning-text{
        display: flex;
        justify-content: center;
    }
    .apply-warning-text p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 180%;
        /* or 22px */
        text-align: center;
    
        margin: 0;
        margin-bottom: 30px;
    
        color: #FFFFFF;
    }
    .apply-warning-file{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 14px;
        cursor: pointer;
        z-index: 2100;
    }
    .apply-warning-file p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 180%;
    
        margin: 0;

        color: #69F5FF;
        cursor: pointer;

        white-space: nowrap;
}

.mobileVersion{
    z-index: 2;
}
}



@media not all and (min-resolution:.001dpcm) { @supports (-webkit-appearance:none) and (stroke-color:transparent) { 
    .apply-button-shadow{
        display: none !important; 
     }
 } }