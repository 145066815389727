@media screen and (min-width: 801px) and (max-width: 4000px){
.green-planet-absolute{
    position: absolute;
    margin-top:-150px;
    height: 300px;
    right: 200px;
}
.stone-about-absolute{
    position: absolute;
}
.stones2-about-absolute{
    position: absolute;
    margin-top:50px;
    margin-left: 150px;
}
.music-meteor-application-dynamic{
    position: absolute;
    height: 250px;
    z-index: 100;
}
.start-rock-group{
    position: absolute;
    right: 0;
    margin-top: 50px;
}

@keyframes turnPlanet {
    0%{
        transform: rotate(0deg);
    }
    50%{
        transform: rotate(180deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
.start-planet{
    top:-225px;
    left: -60px;
    position: absolute;
    height: 450px;
    z-index: 0;
    animation: turnPlanet 60s infinite linear;
}
.meteor-start{
    position: absolute;
    height: 200px;
    top: 0px;
    left: 22%;
}
.application-svg-group{
    position: absolute;
    margin-top:-743px;
}
.img-splash{
    margin-top: 200px;
    height: 700px;
    position: absolute;
    right: 0px;
}
.notes-group-about {
    height: 350px;
    position: absolute;
    right: 0px;
    margin-top: 300px;
}
.planet-about{
    position: absolute;
    margin-left: -10%;
    margin-top: -100px;
}
.music-note-about-dynamic{
    position: absolute;
    margin-left:200px;
    margin-top:250px;
}
.music-note-about-dynamic2{
    position: absolute;
}
.animation-absolute{
    position: absolute;
}
.group-meteors-team{
    height: 400px;
    right: 0;
    margin-top:-200px;
}
.mobileVersion{
    display: none;
}
}

@media screen and (min-width: 200px) and (max-width: 800px){
    .green-planet-absolute{
        position: absolute;
        margin-top:-150px;
        height: 300px;
        right: -150px;
    }
    .stone-about-absolute{
        position: absolute;
        height: 50px;
    }
    .stones2-about-absolute{
        position: absolute;
        height: 100px;

    }
    .music-meteor-application-dynamic{
        position: absolute;
        height: 250px;
        z-index: 100;
    }
    .start-rock-group{
        position: absolute;
        right: 0;
        margin-top: 50px;
    }
    
    @keyframes turnPlanet {
        0%{
            transform: rotate(0deg);
        }
        50%{
            transform: rotate(180deg);
        }
        100%{
            transform: rotate(360deg);
        }
    }
    .start-planet{
        top:-225px;
        left: -60px;
        position: absolute;
        height: 450px;
        z-index: 0;
        animation: turnPlanet 60s infinite linear;
    }
    .meteor-start{
        position: absolute;
        height: 200px;
        top: 0px;
        left: 22%;
    }
    .application-svg-group{
        position: absolute;
        margin-top:-743px;
    }
    .img-splash{
        margin-top: 200px;
        height: 700px;
        position: absolute;
        right: 0px;
    }
    .notes-group-about {
        height: 350px;
        position: absolute;
        right: 0px;
        margin-top: 300px;
    }
    .planet-about{
        position: absolute;
        margin-left: -10%;
        margin-top: -100px;
    }
    .music-note-about-dynamic{
        position: absolute;
        margin-left:200px;
        margin-top:250px;
    }
    .music-note-about-dynamic2{
        position: absolute;
    }
    .animation-absolute{
        position: absolute;
    }
    .group-meteors-team{
        height: 400px;
        right: 0;
        margin-top:-200px;
    }
    .desktopVersion{
        display: none;
    }
}